import { Box, Container, Grid, Typography } from '@mui/material';
import shortCurlyWave from 'assets/images/shortCurlyWave.svg';
import manTestimonial from 'assets/images/manTestimonial.png';
import ginsep from 'assets/images/ginsep.png';
import tata from 'assets/images/tata.png';
import waveStraightShort from 'assets/images/waveStraightShort.png';

export const Testimonial = () => {
  return (
    <Box position="relative" sx={{ overflow: 'hidden' }}>
      <Box component="section" position="relative">
        <Container
          disableGutters
          sx={{
            position: 'relative',
            // height: 620,
            display: 'flex',
            flexDirection: 'column',
            zIndex: 100,
            bgcolor: '#fff',
            height: {
              xs: '30vh',
              md: 620,
            },
          }}
        >
          <Grid
            container
            sx={{
              display: {
                xs: 'flex',
                md: 'none',
              },
            }}
          >
            <Grid item xs={6}></Grid>
            <Grid
              item
              xs={6}
              position="relative"
              top="2vh"
              // justifyContent="flex-start"
              textAlign="left"
            >
              <Typography
                fontWeight={700}
                fontSize={18}
                color="rgba(0, 0, 0, 0.87)"
              >
                Arnab Mandal
              </Typography>
              <Typography color="rgba(0, 0, 0, 0.54)" fontSize={8}>
                POD is an amazing platform for startup investment. Those who
                want to try out their hands at a fast emerging asset class
                &quot;startups&quot; will certainly find it worth exploring.
                Dedicated team at POD ensures personal touch in each step.
              </Typography>
              <Typography
                mt={1.5}
                pr="15%"
                fontWeight={400}
                fontSize={9}
                color="rgba(0, 0, 0, 0.87)"
              >
                Angel Investor Ambassador - GINSEP Corporate Leader - Tata Group
              </Typography>
              <Grid container pr="8px">
                <Grid item xs={6}>
                  <img src={ginsep.src} alt="ginsep logo" />
                </Grid>
                <Grid item xs={6}>
                  <img src={tata.src} alt="tata logo" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexGrow={1}
            sx={{
              display: {
                xs: 'none',
                md: 'inherit',
              },
            }}
          >
            <Grid item md={6}></Grid>
            <Grid item md={6} pr={20} pt={1} textAlign="start">
              <Typography
                fontSize={94}
                color="#C1C7D0"
                component="span"
                lineHeight="110px"
                letterSpacing="0.01"
                position="relative"
                top={65}
              >
                “
              </Typography>
              <Typography color="rgba(0, 0, 0, 0.54)" fontSize={20}>
                POD is an amazing platform for startup investment. Those who
                want to try out their hands at a fast emerging asset class
                &quot;startups&quot; will certainly find it worth exploring.
                Dedicated team at POD ensures personal touch in each step.
              </Typography>
              <Typography
                color="rgba(0, 0, 0, 0.87)"
                fontSize={30}
                fontWeight={700}
                mt={4}
              >
                Arnab Mandal
              </Typography>
              <Typography
                color="rgba(0, 0, 0, 0.87)"
                fontSize={16}
                maxWidth={213}
              >
                Angel Investor GINSEP Ambassador and Corporate Leader at Tata
                Group
              </Typography>
              <Box
                display="flex"
                justifyContent="flex-start"
                mt={2}
                sx={{
                  '& img': {
                    mr: 4,
                  },
                }}
                alignItems="center"
              >
                <img src={ginsep.src} alt="ginsep logo" />
                <img src={tata.src} alt="tata logo" />
              </Box>
            </Grid>
          </Grid>
          <Box
            width="100%"
            sx={{
              '& img': {
                width: '100%',
              },
            }}
            position="absolute"
            left={0}
            bottom={0}
          >
            <img src={shortCurlyWave.src} alt="illustration" />
            <Box
              width="24%"
              position="absolute"
              bottom="36%"
              left="11.5%"
              sx={{ '& img': { width: '100%' } }}
            >
              <img src={manTestimonial.src} alt="illustration" />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        position="absolute"
        bottom={249}
        height={65}
        sx={{ '& img': { height: '100%', width: '900px', objectFit: 'fill' } }}
        width={1000}
        zIndex={5}
      >
        <img src={waveStraightShort.src} alt="wave illustration" />
      </Box>
      <Box
        position="absolute"
        bottom={0}
        height={59}
        sx={{
          '& img': { height: '100%', width: '900px', objectFit: 'fill' },
        }}
        width={1000}
        right={-500}
        zIndex={5}
      >
        <img src={waveStraightShort.src} alt="wave illustration" />
      </Box>
    </Box>
  );
};
