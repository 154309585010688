import { Box, Container, Grid } from '@mui/material';
import boxGroup1 from 'assets/images/boxGroup1.svg';
import boxGroup2 from 'assets/images/boxGroup2.svg';
import iuva from 'assets/images/iuva.svg';
import thatMate from 'assets/images/thatMate.svg';
import taskTracker from 'assets/images/taskTracker.svg';
import karryNow from 'assets/images/karryNow.svg';
import { Heading1 } from '../common/Heading1';

export const AlreadyFunded = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        background: '#DAEFF1',
        position: 'relative',
        borderRadius: '32px',
        // mt: -6,
        mt: {
          xs: -18,
          md: -6,
        },
      }}
    >
      <Container maxWidth="md" sx={{ pt: 3 }}>
        <Heading1>Successfully Funded Startups</Heading1>
      </Container>
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          pb: {
            xs: '60px',
            md: '60px',
          },
        }}
      >
        <Grid
          container
          mx="auto"
          sx={{
            width: {
              xs: '100%',
              md: '70%',
            },
            mt: {
              xs: '30px',
              md: '30px',
            },
          }}
          rowSpacing={6}
        >
          <Grid
            item
            md={3}
            xs={6}
            display="flex"
            alignItems="center"
            justifyContent="center"
            // rowSpacing={2}
            // colSpacing={2}
          >
            <img src={iuva.src} alt="Iuva logo" />
          </Grid>
          <Grid
            item
            md={3}
            xs={6}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img src={thatMate.src} alt="thatMate logo" />
          </Grid>
          <Grid
            item
            md={3}
            xs={6}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img src={taskTracker.src} alt="task tracker logo" />
          </Grid>
          <Grid
            item
            md={3}
            xs={6}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img src={karryNow.src} alt="karry now logo" />
          </Grid>
        </Grid>
      </Box>
      {/* <Box
        // width="70%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mx="auto"
        mt="86px"
        pb="60px"
        flexWrap="wrap"
        sx={{
          width: {
            xs: '90%',
            md: '70%',
          },
          '& img': {
            flexBasis: {
              xs: '50%',
              md: '25%',
            },
            // width: '50px',
          },
        }}
      >
        <Box>
          <img src={iuva.src} alt="Iuva logo" />
        </Box>
        <Box>
          <img src={pod.src} alt="pod logo" />
        </Box>
        <Box>
          <img src={taskTracker.src} alt="Task Tracker logo" />
        </Box>
        <Box>
          <img src={karryNow.src} alt="Karrynow logo" />
        </Box>
      </Box> */}
      <Box position="absolute" left={21} bottom={47}>
        <img src={boxGroup1.src} alt="illustration blog" />
      </Box>
      <Box position="absolute" right={16} top={21}>
        <img src={boxGroup2.src} alt="illustration blog" />
      </Box>
    </Container>
  );
};
