import { Box, Container, Typography } from '@mui/material';
import man from 'assets/images/man.svg';
import box from 'assets/images/box.svg';
import bubble from 'assets/images/bubble.svg';
import chevronRight from 'assets/images/chevronRight.svg';
import chevronRightWhite from 'assets/images/chevronRightWhite.svg';
import { BigButton } from '../common/Buttons/BigButton';
import { AlreadyFunded } from './AlreadyFunded';
import { useUser } from 'context/user.context';
import Link from 'components/Link';

const Dot = () => {
  return (
    <Box
      bgcolor="#C4C4C4"
      borderRadius={10}
      m="12px"
      sx={{
        mx: {
          xs: '6px',
          md: '12px',
        },
        width: {
          xs: '4px',
          md: '6px',
        },
        height: {
          xs: '4px',
          md: '6px',
        },
      }}
    />
  );
};

const EventBox = () => {
  return (
    <Link
      href="https://us02web.zoom.us/meeting/register/tZcvcuCuqjooHdyn1xPAcQuzSmporoMML4or"
      target="_blank"
      rel="noreferrer"
      noLinkStyle
    >
      <Box
        sx={{
          textAlign: {
            xs: 'center',
            md: 'start',
          },
        }}
      >
        <Box
          display="inline-block"
          // mt="140px"
          sx={{
            mt: {
              xs: '40px',
              md: '67px',
              xl: '140px',
            },
          }}
          position="relative"
          zIndex={10}
          textAlign="center"
        >
          <Box
            display="flex"
            borderRadius={30}
            justifyContent="space-between"
            alignItems="center"
            bgcolor="rgba(235, 152, 153, 0.3)"
            px={3}
            sx={{
              '& p': {
                fontSize: {
                  xs: 8,
                  md: 14,
                },
              },
            }}
          >
            <Typography
              fontWeight={500}
              fontSize={14}
              letterSpacing="0.02em"
              sx={{ opacity: 0.7 }}
            >
              Pitch Event
            </Typography>
            <Dot />
            <Typography
              fontWeight={500}
              fontSize={14}
              letterSpacing="0.02em"
              sx={{ opacity: 0.7 }}
            >
              Oct 15, 2022 12:30 PM IST
            </Typography>
            <Dot />
            <Typography
              fontWeight={500}
              fontSize={14}
              letterSpacing="0.02em"
              sx={{ opacity: 0.7, mr: '10px' }}
            >
              Register for the pitch event
            </Typography>
            <img src={chevronRight.src} alt="chevron right icon" />
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export const Hero = () => {
  const [{ user }] = useUser();
  // const [startup, setStartupData] = useState(null);
  // useEffect(() => {
  //   ApiGet(`startup-updates/thatmate`)
  //     .then((res: any) => {
  //       // console.log(res.data);
  //       setStartupData(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  return (
    <>
      <Box
        bgcolor="#F3F9EC"
        position="relative"
        borderRadius="0px 0px 64px 64px"
      >
        <Container
          sx={{
            position: 'relative',
            height: 'calc(90vh - 72px)',
            minHeight: 700,
          }}
        >
          <EventBox />
          {/* {startup && (
            <Link
              href="/startups/thatmate/updates"
              // target="_blank"
              rel="noreferrer"
              noLinkStyle
            >
              <Box
                sx={{
                  textAlign: {
                    xs: 'center',
                    md: 'start',
                  },
                }}
              >
                <Box
                  display="inline-block"
                  // mt="140px"
                  sx={{
                    mt: {
                      xs: '10px',
                      // md: '67px',
                      // xl: '140px',
                    },
                  }}
                  position="relative"
                  zIndex={10}
                  textAlign="center"
                >
                  <Box
                    display="flex"
                    borderRadius={30}
                    justifyContent="space-between"
                    alignItems="center"
                    bgcolor="rgba(235, 152, 153, 0.3)"
                    px={3}
                    sx={{
                      '& p': {
                        fontSize: {
                          xs: 8,
                          md: 14,
                        },
                      },
                    }}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={14}
                      letterSpacing="0.02em"
                      sx={{ opacity: 0.7 }}
                    >
                      That Mate update call
                    </Typography>
                    <Dot />
                    <Typography
                      fontWeight={500}
                      fontSize={14}
                      letterSpacing="0.02em"
                      sx={{ opacity: 0.7 }}
                    >
                      September, 2022
                    </Typography>
                    <Dot />
                    <Typography
                      fontWeight={500}
                      fontSize={14}
                      letterSpacing="0.02em"
                      sx={{ opacity: 0.7, mr: '10px' }}
                    >
                      Q1 FY 2022-23 update
                    </Typography>
                    <img src={chevronRight.src} alt="chevron right icon" />
                  </Box>
                </Box>
              </Box>
            </Link>
          )} */}
          <Typography
            component="h1"
            position="relative"
            zIndex={20}
            sx={{
              fontSize: {
                xs: 60,
                md: 74,
              },
              lineHeight: {
                xs: '80px',
                md: '98px',
              },
              pt: {
                xs: '51px',
                md: '40px',
                xl: '65px',
              },
              px: {
                xs: '30px',
                md: 0,
              },
            }}
            fontWeight={800}
          >
            <Box
              component="span"
              sx={{
                background:
                  'linear-gradient(270.02deg, #4CBFA9 0.02%, rgba(122, 216, 198, 0.04) 99.98%)',
              }}
            >
              Invest in
            </Box>{' '}
            Promising
            {/* <br /> */} <br />
            Indian Startups
          </Typography>
          <Box
            display="flex"
            sx={{
              justifyContent: {
                xs: 'center',
                md: 'flex-start',
              },
              // pb: {
              //   xs: 5,
              // },
            }}
          >
            <Link noLinkStyle href={user ? '/startups' : '/register'}>
              <BigButton
                sx={{
                  mt: {
                    xs: '20px',
                    xl: '30px',
                  },

                  position: 'relative',
                  zIndex: 10,
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    '& img': {
                      height: {
                        xs: 12,
                      },
                    },
                  }}
                >
                  <Typography
                    fontWeight={700}
                    fontSize={24}
                    mr="18px"
                    sx={{
                      fontSize: {
                        xs: 16,
                        md: 24,
                      },
                    }}
                  >
                    {user ? 'View Startups' : 'Join Now'}
                  </Typography>
                  <img src={chevronRightWhite.src} alt="right arrow icon" />
                </Box>
              </BigButton>
            </Link>
          </Box>
          {/* <Box
            position="absolute"
            // bottom={113}
            // width="55%"
            left={0}
            display="flex"
            justifyContent="center"
            sx={{
              '& img': {
                // width: '100%',
                width: {
                  xs: '80%',
                  md: '100%',
                },
              },
              width: {
                xs: '100%',
                md: '55%',
              },
              // bottom: {
              //   xs: 335,
              //   md: 113,
              // },
              top: {
                xs: 260,
                md: '56vh',
                xl: '62vh',
              },
            }}
          >
            <img src={hero1.src} alt="illustration" />
          </Box> */}
          <Box
            position="absolute"
            // top="35vh"
            width="47%"
            sx={{
              '& img': {
                width: '100%',
              },
              display: {
                xs: 'none',
                md: 'inherit',
              },
              top: {
                md: '15vh',
                xl: '15vh',
              },
            }}
            right={0}
          >
            <img src={man.src} alt="illustration" />
          </Box>
          <Box
            position="absolute"
            // top={14}
            // left={-250}
            sx={{
              top: {
                xs: -2,
                md: 14,
              },
              left: {
                xs: -50,
                md: -250,
              },
              // left: -250,
              '& img': {
                // width: '100%',
                width: {
                  xs: '45%',
                  md: '100%',
                },
              },
            }}
          >
            <img src={box.src} alt="illustration" />
          </Box>
          <Box
            position="absolute"
            top={0}
            right={0}
            display="flex"
            justifyContent="flex-end"
            sx={{
              '& img': {
                // width: '100%',
                width: {
                  xs: '40%',
                  md: '100%',
                },
                mr: {
                  xs: 0,
                },
              },
            }}
          >
            <img src={bubble.src} alt="illustration" />
          </Box>
        </Container>
      </Box>
      <AlreadyFunded />
    </>
  );
};
